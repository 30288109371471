const initialState = {
  comment_requests: []
  }
    
    export default function(state = initialState, action) {
      switch(action.type){
        case "COMMENT_REQUETS_INDEX":
          return { ...state,  comment_requests: action.payload }
        default: {
              return state
          }
      }
    }
    