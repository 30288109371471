const initialState = {
    lessons: [],
    lesson: {},
  }
    
    export default function(state = initialState, action) {
      switch(action.type){
        case "LESSONS_INDEX":
          return { ...state,  lessons: action.payload }
        case "LESSONS_DETAILS":
          return {...state, lesson: action.payload}
        default: {
            return state
        }
      }
    }
    