const initialState = {
  products: {results: []}
  }
    
    export default function(state = initialState, action) {
      switch(action.type){
        case "PRODUCTS_INDEX":
          return { ...state,  products: action.payload }
        default: {
              return state
          }
      }
    }
    