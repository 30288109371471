const initialState = {
    main_info: {},
    users_info: {},
    lesson_info: {},
    pages_info: {results: []}

    }
      
      export default function(state = initialState, action) {
        switch(action.type){
            case "STATISTICS_MAIN_USERS":
                return { ...state,  main_info: action.payload }
            case "STATISTICS_MAIN_INFO":
                return { ...state,  users_info: action.payload }
            case "STATISTICS_LESSON_INFO":
                return { ...state,  lesson_info: action.payload }
            case "STATISTICS_PAGES":
                return { ...state,  pages_info: action.payload }
          default: {
                return state
            }
        }
      }
      